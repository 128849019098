import FormPatientSelect from '@/components/form-control/FormPatientSelect.vue'
import FormVitals from '@/components/form-control/FormVitals.vue'
import FormSignature from '@/components/form-control/FormSignature.vue'
import FormFileUpload from '@/components/form-control/FormFileUpload.vue'
import FormDetailCheck from '@/components/form-control/FormDetailCheck'
import FormStartTime from '@/components/form-control/FormStartTime.vue'
import FormStarted from '@/components/form-control/FormStarted.vue'
import FormReferrals from '@/components/form-control/FormReferrals.vue'
import FormProviderType from '@/components/form-control/FormProviderType.vue'
import FormVisitOption from '@/components/form-control/FormVisitOption.vue'
import FormSelfHarm from '@/components/form-control/FormSelfHarm.vue'
import FormNumber from '@/components/form-control/FormNumber.vue'

const controls = {
  patient: {
    name: 'Patient Select',
    description: 'Select a patient',

    configData: {
      name: 'patient',
      validations: [{ ruleType: 'required', errorMessage: 'This field is required', additionalValue: '' }],
    },

    fieldComponent: FormPatientSelect,
    rendererDefaultData() {
      return null
    },
  },
  selfHarm: {
    name: 'Thoughts of Self Harm',
    description: 'Patient having thoughts of self harm?',

    configData: {
      name: 'self',
      containerClass: 'col-md-2',
    },

    fieldComponent: FormSelfHarm,
    rendererDefaultData() {
      return null
    },
  },
  visitOption: {
    name: 'Visit Option',
    description: 'Patient Visit Option',

    configData: {
      name: 'visit',
      validations: [{ ruleType: 'required', errorMessage: 'This field is required', additionalValue: '' }],
      containerClass: 'col-md-5',
    },

    fieldComponent: FormVisitOption,
    rendererDefaultData() {
      return null
    },
  },
  providerType: {
    name: 'Provider Type',
    description: 'Provider Type',

    configData: {
      name: 'provider',
      validations: [{ ruleType: 'required', errorMessage: 'This field is required', additionalValue: '' }],
      containerClass: 'col-md-5',
    },

    fieldComponent: FormProviderType,
    rendererDefaultData() {
      return null
    },
  },
  vitals: {
    name: 'Patient Vitals',
    description: 'Patient Vitals',

    configData: {
      name: 'vitals',
      containerClass: 'col-md-6',
    },

    fieldComponent: FormVitals,
    rendererDefaultData() {
      return null
    },
  },
  referral: {
    name: 'Patient Referral',
    description: 'Refer a Patient',

    configData: {
      name: 'referral',
      containerClass: 'col-md-6',
    },

    fieldComponent: FormReferrals,
    rendererDefaultData() {
      return null
    },
  },
  started: {
    name: 'Recorded Date',
    description: 'Date the Form was Started',

    configData: {
      name: 'recorded',
      validations: [{ ruleType: 'required', errorMessage: 'This field is required', additionalValue: '' }],
    },

    fieldComponent: FormStarted,
    rendererDefaultData() {
      return null
    },
  },
  startTime: {
    name: 'Recorded Time',
    description: 'Time the Form was Started',

    configData: {
      name: 'time',
      validations: [{ ruleType: 'required', errorMessage: 'This field is required', additionalValue: '' }],
    },

    fieldComponent: FormStartTime,
    rendererDefaultData() {
      return null
    },
  },
  detailCheck: {
    name: 'Yes No Details',
    description: 'Yes No Details',

    configData: {
      name: 'checkDetails',
      validations: [{ ruleType: 'required', errorMessage: 'This field is required', additionalValue: '' }],
      containerClass: 'col-md-3',
    },

    fieldComponent: FormDetailCheck,
    rendererDefaultData() {
      return null
    },
  },
  signature: {
    name: 'Signature',
    description: 'Sign',

    configData: {
      name: 'signature',
      validations: [{ ruleType: 'required', errorMessage: 'This field is required', additionalValue: '' }],
    },

    fieldComponent: FormSignature,
    rendererDefaultData() {
      return null
    },
  },
  fileUpload: {
    name: 'File Upload',
    description: 'Select File(s) to Attach',

    configData: {
      name: 'fileUpload',
    },

    fieldComponent: FormFileUpload,
    rendererDefaultData() {
      return null
    },
  },

  numberInput: {
    name: 'Number Input',
    description: 'Enter Whole Numbers',

    configData: {
      name: 'number',
    },

    fieldComponent: FormNumber,
    rendererDefaultData() {
      return null
    },
  },
}

export default controls
