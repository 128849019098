<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
    <v-text-field
      v-model="number"
      type="number"
      :class="controlFieldClass"
      dense
      :readonly="!isEdit"
      v-bind="attrs"
      v-on="on"
      @change="update()"
    ></v-text-field>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'StartedControl',
  extends: BaseControlSkeleton,
  data() {
    return {
      number: null,
      menu: false,
      isEdit: true,

      icons: { mdiCalendar },
    }
  },
  mounted() {
    this.$el.number = this.value
    this.number = this.value != null ? this.value : this.number
    if (this.number != null) {
      this.isEdit = false
    }
    this.updateValue(this.number)
  },
  methods: {
    update() {
      console.log(this.number)
      this.updateValue(this.number)
    },
  },
}
</script>
