import store from '@/store'
import { mdiChartTimelineVariant, mdiCheckCircleOutline, mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'

export default function useProblemList() {
  const icd10List = ref([])
  const problemList = ref([])
  const verificationList = ref([])
  const problemData = ref({})
  const siteProblemData = ref({})

  const tableColumns = [
    { text: 'CODE', value: 'problemCode' },
    { text: 'LABEL', value: 'label' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const patientProblemColumns = [
    { text: 'CODE', value: 'problemDescriptionCode' },
    { text: 'LABEL', value: 'problemLabel' },
    { text: 'ONSET', value: 'onsetDate', width: 175 },
    { text: 'SEVERITY', value: 'problemSeverity.display' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const statusList = ref(['active', 'active-improving', 'active-stable', 'active-worsening', 'inactive', 'resolved'])
  const classificationList = ref(['admission', 'final', 'post-operative', 'pre-operative', 'outpatient', 'discharge'])
  const disciplineList = ref([
    'care team',
    'nursing',
    'medicine',
    'respiratory therapy',
    'occupational therapy',
    'dietary',
  ])
  const certaintyList = ref(['HI - high', 'LO - low', 'ME - medium'])
  const problemAwareness = ref(['full', 'marginal', 'partial'])
  const prognosisList = ref(['good', 'poor'])
  const prognosisAwareness = ref(['full', 'marginal', 'partial'])

  const searchQuery = ref('')
  const statusFilter = ref(null)
  const callResponse = ref(null)
  const totalProblemListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['problemName'],
    sortDesc: [false],
    itemsPerPage: 5
  })
  const siteProblemTotalLocal = ref([])
  const selectedRows = ref([])
  const selectedProblem = ref([])

  const formatDate = date => moment(date).format('YYYY-MM-DD') // moment(date).format('dddd, MMMM Do YYYY')

  // fetch data
  const fetchProblems = () => {
    loading.value = true
    store
      .dispatch('app-problem/fetchProblems')
      .then(response => {
        console.log(response)
        icd10List.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchProblems()
  })

  const fetchICD10Codes = () => {
    loading.value = true
    store
      .dispatch('app-problem/postICD10Problems')
      .then(response => {
        console.log('ICD10 Codes', response)
        icd10List.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchICD10Codes()
  })

  const fetchPatientProblems = patientId => {
    store
      .dispatch('app-problem/fetchPatientProblems', patientId)
      .then(response => {

/*
        response.forEach(element => {
          element.onsetDate = element.onsetDate !== null && element.onsetDate !== undefined ? formatDate(element.onsetDate) : null
          element.identificationDate = element.identificationDate !== null && element.identificationDate !== undefined ? formatDate(element.identificationDate) : null
          element.expectedResolutionDate = element.expectedResolutionDate !== null && element.expectedResolutionDate !== undefined ? formatDate(element.expectedResolutionDate) : null
        })
*/
        problemList.value = response

        console.log(response, problemList)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchPatientProblems()
  })

  // post problem
  const postPatientProblem = patientProblemDTO => {
    console.log('posting Problem', patientProblemDTO)
    patientProblemDTO.onsetDate =
      patientProblemDTO.onsetDate !== null && patientProblemDTO.onsetDate !== undefined
        ? new Date(patientProblemDTO.onsetDate)
        : null
    patientProblemDTO.identificationDate =
      patientProblemDTO.identificationDate !== null && patientProblemDTO.identificationDate !== undefined
        ? new Date(patientProblemDTO.identificationDate)
        : null
    patientProblemDTO.expectedResolutionDate =
      patientProblemDTO.expectedResolutionDate !== null && patientProblemDTO.expectedResolutionDate !== undefined
        ? new Date(patientProblemDTO.expectedResolutionDate)
        : null
    store
      .dispatch('app-problem/postPatientProblem', patientProblemDTO)
      .then(response => {
        console.log(response)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)

        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    postPatientProblem()
  })

  // Condition  Verifications
  const fetchVerifications = () => {
    loading.value = true
    store
      .dispatch('app-problem/fetchConditionVerifications')
      .then(response => {
        console.log(response)
        verificationList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchVerifications()
  })


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveProblemStatusVariantAndIcon = status => {
    if (status === 'Inactive') return { variant: 'error', icon: mdiChartTimelineVariant }
    if (status === 'Active') return { variant: 'success', icon: mdiCheckCircleOutline }

    return { variant: 'secondary', icon: mdiClose }
  }

  return {
    icd10List,
    tableColumns,
    searchQuery,
    statusFilter,
    totalProblemListTable,
    loading,
    options,
    siteProblemTotalLocal,
    selectedRows,
    siteProblemData,
    selectedProblem,
    problemData,
    callResponse,
    patientProblemColumns,
    statusList,
    classificationList,
    disciplineList,
    certaintyList,
    problemAwareness,
    prognosisList,
    prognosisAwareness,

    fetchProblems,
    resolveProblemStatusVariantAndIcon,

    fetchPatientProblems,
    problemList,

    postPatientProblem,

    fetchVerifications,
    verificationList,

    fetchICD10Codes,
  }
}
