import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEdit && _vm.signature == null)?_c(VCard,[(_vm.count == 0)?_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"md":"auto"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.reload()}}},[_vm._v(" Sign ")])],1)],1):_vm._e(),(_vm.count != 0)?_c('div',[_c(VCard,{attrs:{"outlined":""}},[_c('vue-signature-pad',{key:_vm.count,ref:"signaturePad",attrs:{"id":"signaturePad","height":"200px","options":_vm.options}})],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"md":"auto"}},[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.clear()}}},[_vm._v(" Clear ")]),_c(VBtn,{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.undo()}}},[_vm._v(" Undo ")])],1),_c(VSpacer),_c(VCol,{attrs:{"md":"auto"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1)],1):_vm._e()],1):_c(VCard,[_c(VImg,{attrs:{"src":_vm.signature}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }