<template>
  <v-combobox
    key="id"
    v-model="selectedItem"
    :items="icd10List"
    :multiple="multiple"
    :readonly="readonly"
    item-text="label"
    outlined
    label="Select Problem"
    clearable
    dense
    @change="selectProblem(selectedItem)"
  >
  </v-combobox>
</template>

<script>
import problemService from '@/services/ProblemService'
import useProblemService from '@/store/useProblemService'

import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

export default {
  props: {
    problem: {
      type: Object,
      default: () => ({}),
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    console.log('Problem Prop', props.problem, props.readonly)
    const PROBLEM_APP = 'app-problem'

    // Register module
    if (!store.hasModule(PROBLEM_APP)) {
      store.registerModule(PROBLEM_APP, problemService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROBLEM_APP)) store.unregisterModule(PROBLEM_APP)
    })

    const { icd10List, fetchProblems } = useProblemService()
    const selectedItem = ref(props.problem.label)
    const requiredRules = [v => v !== null || 'Select one problem.']

    const selectProblem = () => {
      console.log('', selectedItem)
      emit('problem-select', selectedItem.value)
    }

    return {
      requiredRules,

      fetchProblems,
      icd10List,
      selectedItem,

      selectProblem,
    }
  },
  mounted() {
    this.fetchProblems()
  },
}
</script>
