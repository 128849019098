<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
    <v-row>
      <v-col>
        <v-radio-group
          v-model="check"
          row
          :readonly="!isEdit"
        >
          <v-radio
            v-for="option, index in radioOptions"
            :key="index"

            :label="option"

            :value="option"
            @change="updateCheck(option)"
          >
          </v-radio>
        </v-radio-group>

        <div
          v-if="check == 'Yes'"
        >
          <v-textarea
            v-model="details"
            dense
            label="Describe"
            :value="details"
            outlined
            :readonly="!isEdit"
            @change="updateDetails(details)"
          >
          </v-textarea>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'

export default {
  name: 'DetailCheckControl',
  extends: BaseControlSkeleton,
  data() {
    return {
      check: null,
      radioOptions: ['No', 'Yes'],
      isEdit: true,
      details: null,
      detailCheck: { check: 'No', details: null },
    }
  },
  mounted() {
    this.$el.check = this.value

    this.check = this.value != null ? this.value.check : this.check
    if (this.check != null) {
      console.log('Check', this.value)
      this.isEdit = false
      this.updateCheck(this.value.check)
      this.updateDetails(this.value.details)
    } else {
      this.check = 'No'
    }
    this.updateValue(this.detailCheck)
  },

  methods: {
    updateCheck(option) {
      this.check = option
      this.update()
    },
    updateDetails(details) {
      this.details = details
      this.update()
    },

    update() {
      this.detailCheck.check = this.check
      this.detailCheck.details = this.details
      this.updateValue(this.detailCheck)
    },
  },
}
</script>
