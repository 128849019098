import { mdiPill, mdiDoctor, mdiHospitalBuilding, mdiAllergy, mdiAlertRhombusOutline, mdiHeadAlertOutline, mdiFileClockOutline } from '@mdi/js'

export default [
  {
    subheader: 'GLOBAL ADMINISTRATORS',
    resource: 'Admin',
  },
  {
    title: 'Site Setting List',
    to: 'site-setting-list',
    icon: mdiFileClockOutline,
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Site List',
    to: 'admin-site-list',
    icon: mdiHospitalBuilding,
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Allergy List',
    to: 'admin-allergy-list',
    icon: mdiAllergy,
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Immunization List',
    to: 'admin-immunization-list',
    icon: mdiAllergy,
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Problem List',
    to: 'admin-problem-list',
    icon: mdiHeadAlertOutline,
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Alert List',
    to: 'admin-alert-list',
    icon: mdiAlertRhombusOutline,
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Medication Search',
    to: 'site-med-select',
    icon: mdiPill,
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Role List',
    to: 'admin-role-list',
    icon: mdiDoctor,
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Symptom List',
    to: 'admin-symptom-list',
    icon: mdiAllergy,
    resource: 'Admin',
    action: 'manage',
  },
]
