<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="isConfirmDialogActive"
        persistent
        max-width="25%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Confirmation</span>
          </v-card-title>
          <v-card-text>
            <span> {{ selectedItem }}</span>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  text
                  @click="closeDialog()"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="confirm()"
                >
                  Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'isConfirmDialogActive',
    event: 'update:is-confirm-dialog-active',
  },
  props: {
    isConfirmDialogActive: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const closeDialog = () => {
      emit('update:is-confirm-dialog-active', false)
    }

    const confirm = () => {
      emit('confirmation', true)
      closeDialog()
    }

    return {
      closeDialog,
      confirm,
    }
  },
}
</script>
