<template>
  <div
    :id="control.uniqueId"
    :type="control.typeAttribute"
    :name="control.name || control.uniqueId"
  >
    <patient-select
      v-model="patient"
      :patient="patient"
      :readonly="!isEdit"
      @patient-select="updatePatientId"
    ></patient-select>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'
import PatientSelect from '@/components/patient/PatientSelect.vue'

export default {
  name: 'PatientControl',
  components: {
    PatientSelect,
  },
  extends: BaseControlSkeleton,
  data() {
    return {
      patientId: null,
      patient: {},
      isEdit: true,
    }
  },
  created() {
    console.log('Patient Control', this.value, this.patientId)
    this.patientId = this.value != null ? this.value : null
    if (this.patientId != null) {
      this.isEdit = false
    } else {
      this.patientId = this.$route.params.patientId
    }

    this.patient = { id: this.patientId }

    console.log('Patient Control', this.value, this.patientId, this.patient)
    this.updateValue(this.patientId)
  },
  methods: {
    updatePatientId(patientDTO) {
      this.patientId = patientDTO.id
      this.updateValue(this.patientId)
    },
  },
}
</script>
