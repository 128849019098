<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
    <v-row>
      <template
        v-for="value, index in vitalOptions"
      >
        <v-col
          v-if="index != 'BloodSugarComments'"
          :key="index"
          cols="3"
        >
          <v-text-field
            type="input"
            v-model="vitals[index]"
            dense
            :label="index"
            :value="value"
            :class="controlFieldClass"
            :hint="vitalUnit[index]"
            :readonly="!isEdit"
            @change="update(value, index)"
          >
            {{vitalUnit[index]}}
          </v-text-field>
        </v-col>
        <v-col
          v-else
          :key="index"
          cols="12"
        >
          <v-textarea
            v-model="vitals[index]"
            dense
            :label="index"
            :value="value"
            outlined
            :hint="vitalUnit[index]"
            :readonly="!isEdit"
            :rows="2"
            no-resize
            @change="update(value, index)"
          >
            {{vitalUnit[index]}}
          </v-textarea>
        </v-col>

      </template>
    </v-row>

    <single-confirmation
      v-model="isConfirmDialogActive"
      :selected-item="selectedItem"
      @confirmation="isConfirmDialogActive = false"
    ></single-confirmation>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'
import { betweenValidator } from '@core/utils/validation'
import SingleConfirmation from '@/components/validation/SingleConfirmation.vue'

export default {
  name: 'VitalsControl',
  components: { SingleConfirmation },
  extends: BaseControlSkeleton,
  data() {
    return {
      isEdit: true,
      isConfirmDialogActive: false,
      selectedItem: null,
      vitals: null,
      vitalOptions: {
        Temperature: null,
        Pulse: null,
        Respirations: null,
        O2SaturationPercent: null,
        Systolic: null,
        Diastolic: null,
        Weight: null,
        Height: null,
        PrePeakFlow: null,
        PostPeakFlow: null,
        BloodSugar: null,
        BloodSugarComments: null,
      },
      vitalRec: {
        Temperature: { min: 94, max: 102 },
        Pulse: { min: 60, max: 100 },
        Respirations: { min: 11, max: 35 },
        O2SaturationPercent: { min: 84, max: 100 },
        Systolic: { min: 90, max: 180 },
        Diastolic: { min: 60, max: 120 },
        Weight: { min: 80, max: 650 },
        Height: { min: 35, max: 350 },
        PrePeakFlow: { min: 200, max: 550 },
        PostPeakFlow: { min: 200, max: 550 },
        BloodSugar: { min: 59, max: 356 },
      },
      vitalUnit: {
        Temperature: '°F',
        Pulse: 'bpm',
        Respirations: '/min',
        O2SaturationPercent: '%',
        Systolic: 'mmHg',
        Diastolic: 'mmHg',
        Weight: 'lbs',
        Height: 'in',
        PrePeakFlow: 'L/min',
        PostPeakFlow: 'L/min',
        BloodSugar: 'mg/dL',
        BloodSugarComments: null,
      },
    }
  },
  setup() {
    return {
      betweenValidator,
    }
  },
  mounted() {
    console.log('Vitals', this.value)

    if ((this.value == null && this.value == undefined) || (this.value.Temperature == null && this.value.Pulse == null && this.value.Respirations == null && this.value.Systolic == null &&
        this.value.Diastolic == null && this.value.O2SaturationPercent == null && this.value.Weight == null &&  this.value.PrePeakFlow == null &&
        this.value.Height == null && this.value.PostPeakFlow == null && this.value.BloodSugar == null && this.value.BloodSugarComments == null))
      {
        this.vitals =  null
      }
    else {
      this.vitals = this.value
    }

    if (this.vitals != null) {
      this.isEdit = false
    } else {
      this.vitals = this.vitalOptions
    }
    this.updateValue(this.vitals)
  },

  methods: {
    update(value, index) {
      this.selectedItem = null

      const isBetween = this.betweenValidator(value, this.vitalRec[index]['min'], this.vitalRec[index]['max'])

      if (isBetween !== true) {
        this.selectedItem = isBetween
        this.isConfirmDialogActive = true
      }

      this.updateValue(this.vitals)
    },
  },
}
</script>
