<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
    <v-radio-group
      v-model="visitOption"
      row
      :readonly="!isEdit"
    >
      <v-radio
        v-for="option, index in radioOptions"
        :key="index"

        :label="option"

        :value="option"
        @change="update(option)"
      >
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'

export default {
  name: 'VisitOptionControl',
  extends: BaseControlSkeleton,
  data() {
    return {
      visitOption: null,
      radioOptions: ['New-Visit', 'Follow-Up', 'Telemedicine'],
      isEdit: true,
    }
  },
  mounted() {
    this.$el.visitOption = this.value
    this.visitOption = this.value != null ? this.value : this.visitOption
    if (this.visitOption != null) {
      this.isEdit = false
    } else {
      this.visitOption = 'New-Visit'
    }
    this.updateValue(this.visitOption)
  },

  methods: {
    update(option) {
      this.visitOption = option
      this.updateValue(this.visitOption)
    },
  },
}
</script>
