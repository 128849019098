import { mdiAccountOutline, mdiClipboardListOutline, mdiFileDocumentOutline, mdiPill } from '@mdi/js'

export default [
  {
    subheader: 'MEDICAL',
    resource: 'Medical',
    action: 'read',
  },
  {
    title: 'Patient List',
    icon: mdiAccountOutline,
    to: 'site-patient-list',
    resource: 'Medical',
    action: 'read',
  },
  {
    title: 'Task Lists',
    icon: mdiClipboardListOutline,
    to: 'site-task-list',
    resource: 'Medical',
    action: 'read',
  },
  {
    title: 'Reports',
    icon: mdiFileDocumentOutline,
    to: 'site-report-list',
    resource: 'Medical',
    action: 'read',
  },
  {
    title: 'Med Pass',
    icon: mdiFileDocumentOutline,
    to: 'site-mar',
    resource: 'OfflineMAR',
    action: 'read',
  },

  // Reviews
  {
    subheader: 'REVIEW',
    resource: 'Medical',
    action: 'read',
  },
  {
    title: 'Document Review',
    icon: mdiFileDocumentOutline,
    to: 'site-document-review',
    resource: 'Medical',
    action: 'read',
  },
  {
    title: 'Practitioner Review',
    icon: mdiFileDocumentOutline,
    to: 'site-practitioner-review',
    resource: 'Practitioner',
    action: 'read',
  },

  // Services
  {
    subheader: 'SERVICES',
    //resource: 'Medical',
    //action: 'read',
    resource: 'Admin',
    action: 'manage',
  },
  /*{

    title: 'Script Check',
    icon: mdiPill,
    resource: 'Medical',
    action: 'read',
    children: [
      {
        title: 'New Entry',
        to: 'apps-med-check',
        resource: 'Medical',
        action: 'read',
      },
      {
        title: 'Entry Log',
        to: 'apps-med-check-log',
        resource: 'Medical',
        action: 'read',
      },
    ],
  },
  */
  {
    title: 'History Compiler',
    icon: mdiFileDocumentOutline,
    to: 'wip',
    //resource: 'Medical',
    //action: 'read',
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Patient Merge',
    icon: mdiFileDocumentOutline,
    to: 'wip',
    //resource: 'Medical',
    //action: 'read',
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Record Retention',
    icon: mdiFileDocumentOutline,
    to: 'wip',
    //resource: 'Medical',
    //action: 'read',
    resource: 'Admin',
    action: 'manage',
  },
]
