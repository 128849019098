<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
    <task-type-select
      :type-ids="referral.taskTypeId"
      :readonly="!isEdit"
      @task-type-select="update"
    ></task-type-select>

    <div
      v-if="referral.taskTypeId[0] != undefined"
    >
      <div>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="referral.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="referral.date"
              :class="controlFieldClass"
              dense
              label="DueDate"
              :prepend-icon="icons.mdiCalendar"
              :readonly="!isEdit"
              v-bind="attrs"
              v-on="on"
              @change="updateValue(referral)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="referral.date"
            no-title
            scrollable
            :readonly="!isEdit"
            @change="updateValue(referral)"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(referral.date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>

      <div
        v-if="isChronic"
      >
        <problem-select
          v-if="isChronic"
          :is-edit="isEdit"
          @problem-select="updateChronic"
        ></problem-select>
        <v-spacer></v-spacer>
      </div>

      <div>
        <v-text-field
          v-model="referral.description"
          :class="controlFieldClass"
          :readonly="!isEdit"
          dense
          label="Description"
          @change="updateValue(referral)"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'
import TaskTypeSelect from '@/components/task/TaskTypeSelect.vue'
import ProblemSelect from '@/components/problem/ProblemSelect.vue'
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'ReferralControl',
  components: {
    TaskTypeSelect,
    ProblemSelect,
  },
  extends: BaseControlSkeleton,
  data() {
    return {
      menu: false,
      isChronic: false,
      isEdit: true,

      referral: {
        taskTypeId: [],
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        problem: [],
        description: 'Referred document to review',
      },

      icons: { mdiCalendar },
    }
  },
  mounted() {
    this.$el.referral = this.value

    let referral = null

    if (this.value.taskTypeId.length != 0) {
      referral = this.value
      this.referral = this.value
      console.log('Referral', this.value, this.referral)
    }

    //this.referral = this.value != null ? this.value : null
    if (referral != null) {
      this.isEdit = false
    }
    this.updateValue(this.referral)
    console.log('Referral2', this.referral, this.isEdit)
  },
  methods: {
    update(taskTypeDTO) {
      if (taskTypeDTO !== undefined && taskTypeDTO.length !== 0) {
        taskTypeDTO.forEach(type => {
          if (!this.referral.taskTypeId.includes(type.id)) {
            this.referral.taskTypeId.push(type.id)
            if (type.display === 'Chronic Clinic') {
              this.isChronic = true
            }
          }
        })
      } else {
        this.referral.taskTypeId = []
        this.isChronic = false
      }

      this.updateValue(this.referral)
    },
    updateChronic(problemDTO) {
      problemDTO.forEach(problemCode => {
        if (!this.referral.problem.includes(problemCode.problemCode)) {
          this.referral.problem.push(problemCode.problemCode)
        }
      })
      this.updateValue(this.referral)
    },
  },
}
</script>
