const admin = [
  //
  //* ——— Site List ——————————————————
  //

  {
    path: '/admin/site-list',
    name: 'admin-site-list',
    component: () => import('@/views/site/list/SiteList.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },

  //
  //* ——— Allergy List ——————————————————
  //

  {
    path: '/admin/allergy-list',
    name: 'admin-allergy-list',
    component: () => import('@/views/allergy/AllergyList.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },

  //
  //* ——— Problem List ——————————————————
  //

  {
    path: '/admin/problem-list',
    name: 'admin-problem-list',
    component: () => import('@/views/problem/ProblemList.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },

  //
  //* ——— Alert List ——————————————————
  //

  {
    path: '/admin/alert-list',
    name: 'admin-alert-list',
    component: () => import('@/views/alert/AlertList.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },

   //
  //* ——— Immunization List ——————————————————
  //

  {
    path: '/admin/immunization-list',
    name: 'admin-immunization-list',
    component: () => import('@/views/immunization/ImmunizationList.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },


  //
  //* ——— Role ——————————————————
  //
  {
    path: '/admin/role-list',
    name: 'admin-role-list',
    component: () => import('@/views/role/RoleList.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },

  //
  //* ——— WIP ——————————————————
  //

  {
    path: '/wip',
    name: 'wip',
    component: () => import('@/views/WIP.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
]

export default admin
