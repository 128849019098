import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sitePatientList.length != 0)?_c(VCombobox,{attrs:{"id":"patientSelect","items":_vm.sitePatientList,"rules":_vm.requiredRules,"prepend-inner-icon":_vm.icons.mdiAccountOutline,"readonly":_vm.readonly,"item-text":"jacketId","validate-on-blur":"","small-chips":"","cache-items":"","hide-details":"","outlined":"","dense":"","item-value":"id","label":"Patient ID"},on:{"change":function($event){return _vm.selectPatient()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" Search for the Patient ID or type in the "),_c('strong',[_vm._v("Patient ID")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c(VChip,_vm._g(_vm._b({attrs:{"input-value":selected}},'v-chip',attrs,false),on),[_c(VAvatar,{attrs:{"left":""}},[_c(VImg,{attrs:{"src":require("@/assets/images/avatars/1.png")}})],1),_c('strong',[_vm._v(_vm._s(item.jacketId)+" | "+_vm._s(item.displayName))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemAvatar,{attrs:{"color":"indigo","height":"40px"}},[_c(VImg,{attrs:{"src":require("@/assets/images/avatars/1.png")}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c('strong',[_vm._v(_vm._s(item.jacketId))]),_vm._v(" ("+_vm._s(item.displayName)+")")])],1)]}}],null,false,3009071338),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }