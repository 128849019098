import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboards',
    icon: mdiHomeOutline,
    badge: '1',
    badgeColor: 'warning',
    resource: 'Public',
    children: [
      {
        title: 'Home',
        to: 'dashboard-home',
      },
      /*
      {
        title: 'Statistics',
        to: 'dashboard-statistics',
      },
      */
    ],
  },
]
