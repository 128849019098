const variables = {
  // PROD
  INTERNAL_API: 'https://detainehr.azurewebsites.net',

  // LOCAL
  // INTERNAL_API: 'https://localhost:5001',

  // DEV
  // INTERNAL_API: 'https://detainehr-dev.azurewebsites.net',

  // Staging
  // INTERNAL_API: 'https://detainehr-staging.azurewebsites.net',
}

export default variables
