const medical = [
  //
  //* ——— Patient List ——————————————————
  //

  {
    path: '/site/patient-list',
    name: 'site-patient-list',
    component: () => import('@/views/patient/list/PatientList.vue'),
    meta: {
      layout: 'content',
      resource: 'Medical',
    },
  },

  //
  //* ——— Patient Chart ——————————————————
  //

  {
    path: '/site/patient-chart/:patientId',
    name: 'site-patient-chart',
    component: () => import('@/views/patient/chart/PatientChart.vue'),
    props: {
      patientId: {
        type: String,
        required: true,
      },
    },
    meta: {
      layout: 'content',
      resource: 'Medical',
    },
  },

  //
  //* ——— Task List ——————————————————
  //

  {
    path: '/site/task-list',
    name: 'site-task-list',
    component: () => import('@/views/task/TaskList.vue'),
    meta: {
      layout: 'content',
      resource: 'Medical',
    },
  },

  //
  //* ——— Medication  Order ——————————————————
  //

  {
    path: '/site/medication/med-select',
    name: 'site-med-select',
    component: () => import('@/components/medication/MedicationSelect.vue'),
    meta: {
      layout: 'content',
      resource: 'Medical',
    },
  },

  //
  //* ——— SureScripts  Interface —————————————————— WIP —
  //
  {
    path: '/apps/med-check',
    name: 'apps-med-check',
    component: () => import('@/apps/medcheck/MedicationCheck.vue'),
    meta: {
      layout: 'content',
      resource: 'Medical',
    },
  },
  {
    path: '/apps/med-check-log',
    name: 'apps-med-check-log',
    component: () => import('@/apps/medcheck/MedicationCheckLog.vue'),
    meta: {
      layout: 'content',
      resource: 'Medical',
    },
  },

  //
  //* ——— Reports ——————————————————
  //

  {
    path: '/site/report-list',
    name: 'site-report-list',
    component: () => import('@/views/report/ReportList.vue'),
    meta: {
      layout: 'content',
      resource: 'Medical',
    },
  },

  //
  //* ——— Review ——————————————————
  //

  {
    path: '/site/document-review',
    name: 'site-document-review',
    component: () => import('@/views/review/DocumentReview.vue'),
    meta: {
      layout: 'content',
      resource: 'Medical',
    },
  },
  {
    path: '/site/practitioner-review',
    name: 'site-practitioner-review',
    component: () => import('@/views/review/PractitionerReview.vue'),
    meta: {
      layout: 'content',
      resource: 'Practitioner',
    },
  },

  //
  //* ——— MAR ——————————————————
  //

  {
    path: '/site/mar',
    name: 'site-mar',
    component: () => import('@/views/mar/MARList.vue'),
    meta: {
      layout: 'content',
      resource: 'OfflineMAR',
    },
  },
]

export default medical
