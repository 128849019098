<template>
  <div>
    <v-card
      v-if="isEdit && signature == null"
    >
      <v-row
        v-if="count == 0"
        justify="center"
      >
        <v-col
          md="auto"
        >
          <v-btn
            color="primary"
            @click="reload()"
          >
            Sign
          </v-btn>
        </v-col>
      </v-row>
      <div
        v-if="count != 0"
      >
        <v-card
          outlined
        >
          <vue-signature-pad
            id="signaturePad"
            ref="signaturePad"
            :key="count"
            height="200px"
            :options="options"
          >
          </vue-signature-pad>
        </v-card>

        <v-row
          justify="center"
        >
          <v-col
            md="auto"
          >
            <v-btn
              color="error"
              @click="clear()"
            >
              Clear
            </v-btn>

            <v-btn
              color="secondary"
              @click="undo()"
            >
              Undo
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>

          <v-col
            md="auto"
          >
            <v-btn
              color="primary"
              @click="save()"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card
      v-else
    >
      <v-img
        :src="signature"
      ></v-img>
    </v-card>
  </div>
</template>
<script>
import VueSignaturePad from 'vue-signature-pad'

export default {
  components: { VueSignaturePad },
  props: {
    signature: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isEdit: true,
      count: 0,
      options: {
        dotSize: (0.5 + 2.5) / 2,
        minWidth: 0.5,
        maxWidth: 2.5,
        throttle: 16,
        minDistance: 5,
        backgroundColor: 'rgba(0,0,0,0)',
        penColor: 'black',
        velocityFilterWeight: 0.7,
        onBegin: () => {
          console.log('=== Begin ===', this.$refs.signaturePad, this.$refs.signaturePad.$refs.signaturePadCanvas)
        },
        onEnd: () => {
          console.log('=== End ===')
        },
      },
    }
  },
  setup(props, { emit }) {
    console.log('Setup Signature', props.signature)
    const sign = image => {
      console.log('Sig Emit')
      emit('sign', image)
    }

    return {
      sign,
    }
  },
  mounted() {
    console.log('Mounted Signature', this.$refs.signaturePad, this.options)
    if (this.signature != null) {
      console.log('load')
      //this.load()
      this.isEdit = false
      //this.$refs.signaturePad.lockSignaturePad()
    }

    this.update(this.signature)
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    clear() {
      this.$refs.signaturePad.clearSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      console.log('save', data)
      if (!isEmpty) {
        // Save Data URI to Sever
        this.signature = data
        this.update(this.signature)
      }
    },
    reload() {
      this.count++
      console.log('Counter', this.count)
    },
    load() {
      console.log('Loading Image')

      //this.$refs.signaturePad.fromDataURL(this.signature)
    },
    update(signature) {
      this.signature = signature
      console.log('Updated')
      //this.updateValue(this.signature)
      this.sign(this.signature)
    },
  },
}
</script>
