<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
  <v-row>
    <v-col
      v-if="isEdit"
      cols="12"
    >
      <v-file-input
        outlined
        dense
        counter
        multiple
        placeholder="Browse for file(s)"
        label="Upload File(s)"
        :show-size="1000"
        v-model="files"
      >
      <template v-slot:selection="{ index, text }">
        <v-chip
          v-if="index < 2"
          label
          small
        >
          {{ text }}
        </v-chip>

        <span
          v-else-if="index === 2"
          class="text-overline grey--text text--darken-3 mx-2"
        >
          +{{ files.length - 2 }} File(s)
        </span>
      </template>
      </v-file-input>
    </v-col>
    <v-col
      v-else
      cols="12"
    >
        <div
        v-for="(image, index) in base64"
        :key="index"
        >
          <div
            v-if="image !== '' && image !== null"
          >
            Attachment {{ index + 1 }}
            <v-chip
              @click="exportForm(index + 1, image)"
            >
              <v-icon>
                {{mdiFileDocumentOutline}}
              </v-icon>
            </v-chip>
          </div>
        </div>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'
import { mdiFileDocumentOutline } from '@mdi/js'

export default {
  name: 'FileUploadControl',
  extends: BaseControlSkeleton,
  data() {
    return {
      files: [],
      base64: [],
      isEdit: false,
    }
  },
  setup(){
    return {
      mdiFileDocumentOutline,
    }
  },
  watch: {
    files: function (newVal, oldVal) {
      console.log('File Watch',newVal, oldVal)
      if(newVal) {
        newVal.forEach(element => {
          console.log(element)
          this.createBase64Image(element);
        })
        this.updateFiles()
      } else {
        this.base64 = [];
      }
    }
  },
  created() {
    this.base64 = this.value != null ? this.value : []
    if (this.base64.length == 0) { this.isEdit = true }

    console.log('Mounted Form Upload', this.base64)

    this.updateFiles()
  },
  methods: {
    createBase64Image (FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64.push(event.target.result);
        console.log('File Base',event.target.result, this.base64)
      }
      reader.readAsDataURL(FileObject)
    },
    updateFiles() {
      console.log('File Upload Update', this.base64)
      this.updateValue(this.base64)
    },
    exportForm(index, data){
      const downloadLink = document.createElement("a")
      downloadLink.href = data
      downloadLink.download = index
      downloadLink.click()
    }
  },
}
</script>
