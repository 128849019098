// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiAlphaTBoxOutline,
  mdiArchiveOutline,
  mdiCalendarOutline,
  mdiChartDonut,
  mdiCheckboxMarkedCircleOutline,
  mdiContentCopy,
  mdiControllerClassicOutline,
  mdiCreditCardOutline,
  mdiEmailOutline,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFileOutline,
  mdiHomeOutline,
  mdiMapOutline,
  mdiMenu,
  mdiMessageTextOutline,
  mdiShieldOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'TEMPLATE',
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'Template Pages',
    icon: mdiMenu,
    resource: 'Admin',
    action: 'manage',
    children: [
      {
        subheader: 'Spotlight',
      },
      {
        title: 'Spotlights',
        icon: mdiHomeOutline,
        badge: '3',
        badgeColor: 'warning',
        children: [
          {
            title: 'CRM',
            to: 'dashboard-crm',
          },
          {
            title: 'Analytics',
            to: 'dashboard-analytics',
          },
          {
            title: 'eCommerce',
            to: 'dashboard-eCommerce',
          },
        ],
      },
      {
        subheader: 'OTHER',
      },
      {
        title: 'Access Control',
        to: { name: 'page-access-control' },
        icon: mdiShieldOutline,
        resource: 'ACL',
        action: 'read',
      },
      {
        subheader: 'APPS & PAGES',
      },
      {
        title: 'Calendar',
        icon: mdiCalendarOutline,
        to: 'apps-calendar',
      },
      {
        title: 'Chat',
        icon: mdiMessageTextOutline,
        to: 'apps-chat',
      },
      {
        title: 'Email',
        icon: mdiEmailOutline,
        to: 'apps-email',
      },
      {
        title: 'Knowledge Base',
        icon: mdiFileDocumentOutline,
        to: 'page-knowledge-base',
      },
      {
        title: 'FAQ',
        icon: mdiFileDocumentOutline,
        to: 'page-faq',
      },
      {
        title: 'Invoices',
        icon: mdiFileDocumentOutline,
        children: [
          {
            title: 'List',
            to: 'apps-invoice-list',
          },
          {
            title: 'Preview',
            to: { name: 'apps-invoice-preview', params: { id: 4987 } },
          },
          {
            title: 'Edit',
            to: { name: 'apps-invoice-edit', params: { id: 4987 } },
          },
          {
            title: 'Add',
            to: { name: 'apps-invoice-add' },
          },
        ],
      },
      {
        title: 'User',
        icon: mdiAccountOutline,
        children: [
          {
            title: 'User List',
            to: 'apps-user-list',
          },
          {
            title: 'User View',
            to: { name: 'apps-user-view', params: { id: 21 } },
          },
        ],
      },
      {
        title: 'Pages',
        icon: mdiFileOutline,
        children: [
          {
            title: 'Authentication',
            children: [
              {
                title: 'Login V1',
                to: 'auth-login-v1',
                target: '_blank',
              },
              {
                title: 'Login V2',
                to: 'auth-login-v2',
                target: '_blank',
              },
              {
                title: 'Register V1',
                to: 'auth-register-v1',
                target: '_blank',
              },
              {
                title: 'Register V2',
                to: 'auth-register-v2',
                target: '_blank',
              },
              {
                title: 'Forgot Password V1',
                to: 'auth-forgot-password-v1',
                target: '_blank',
              },
              {
                title: 'Forgot Password V2',
                to: 'auth-forgot-password-v2',
                target: '_blank',
              },
              {
                title: 'Reset Password V1',
                to: 'auth-reset-password-v1',
                target: '_blank',
              },
              {
                title: 'Reset Password V2',
                to: 'auth-reset-password-v2',
                target: '_blank',
              },
            ],
          },
          {
            title: 'Miscellaneous',
            children: [
              {
                title: 'Under Maintenance',
                to: 'misc-under-maintenance',
                target: '_blank',
              },
              {
                title: 'Error',
                to: 'misc-error',
                target: '_blank',
              },
              {
                title: 'Coming Soon',
                to: 'misc-coming-soon',
                target: '_blank',
              },
              {
                title: 'Not Authorized',
                to: 'misc-not-authorized',
                target: '_blank',
              },
            ],
          },
          {
            title: 'Knowledge Base',
            to: 'page-knowledge-base',
          },
          {
            title: 'Account Settings',
            to: 'page-account-settings',
          },
          {
            title: 'Pricing',
            to: 'page-pricing',
          },

          {
            title: 'FAQ',
            to: 'page-faq',
          },
        ],
      },
      {
        subheader: 'FORMS & TABLES',
      },
      {
        title: 'Forms Elements',
        icon: mdiContentCopy,
        children: [
          {
            title: 'Autocomplete',
            to: 'form-autocomplete',
          },
          {
            title: 'Checkbox',
            to: 'form-checkbox',
          },
          {
            title: 'Combobox',
            to: 'form-combobox',
          },
          {
            title: 'Date Picker',
            to: 'form-datepicker',
          },
          {
            title: 'File Input',
            to: 'form-file-input',
          },
          {
            title: 'Month Picker',
            to: 'form-month-picker',
          },
          {
            title: 'Radio',
            to: 'form-radio',
          },
          {
            title: 'Range Slider',
            to: 'form-range-slider',
          },
          {
            title: 'Rating',
            to: 'form-rating',
          },
          {
            title: 'Select',
            to: 'form-select',
          },
          {
            title: 'Slider',
            to: 'form-slider',
          },
          {
            title: 'Switch',
            to: 'form-switch',
          },
          {
            title: 'Textarea',
            to: 'form-textarea',
          },
          {
            title: 'Textfield',
            to: 'form-textfield',
          },
          {
            title: 'Time Picker',
            to: 'form-time-picker',
          },
        ],
      },
      {
        title: 'Form Layout',
        to: 'form-layout',
        icon: mdiContentCopy,
      },
      {
        icon: mdiCheckboxMarkedCircleOutline,
        title: 'Form validation',
        to: 'form-validation',
      },
      {
        title: 'Tables',
        icon: mdiContentCopy,
        children: [
          {
            title: 'Simple Table',
            to: 'table-simple',
          },
          {
            title: 'Datatable',
            to: 'table-data-table',
          },
        ],
      },
      {
        subheader: 'CHARTS AND MAPS',
      },
      {
        title: 'Charts',
        icon: mdiChartDonut,
        badge: '2',
        badgeColor: 'error',
        children: [
          {
            title: 'Apex Chart',
            to: 'apex-chart',
          },
          {
            title: 'Chartjs',
            to: 'chartjs-chart',
          },
        ],
      },
      {
        icon: mdiMapOutline,
        title: 'Leaflet Maps',
        to: 'leaflet-maps',
      },
      {
        subheader: 'USER INTERFACE',
      },
      {
        icon: mdiAlphaTBoxOutline,
        title: 'Typography',
        to: 'ui-typography',
      },
      {
        icon: mdiEyeOutline,
        title: 'Icons',
        to: 'ui-icons',
      },
      {
        icon: mdiControllerClassicOutline,
        title: 'Gamification',
        to: 'ui-gamification',
      },
      {
        title: 'Cards',
        icon: mdiCreditCardOutline,
        badge: 'New',
        badgeColor: 'success',
        children: [
          {
            title: 'Basic',
            to: 'card-basic',
          },
          {
            title: 'Statistics',
            to: 'card-statistics',
          },
          {
            title: 'Advance',
            to: 'card-advance',
          },
          {
            title: 'Actions',
            to: 'card-actions',
          },
          {
            title: 'Chart',
            to: 'card-chart',
          },
        ],
      },
      {
        title: 'Components',
        icon: mdiArchiveOutline,
        children: [
          {
            title: 'Alert',
            to: 'components-alert',
          },
          {
            title: 'Avatar',
            to: 'components-avatar',
          },
          {
            title: 'Badge',
            to: 'components-badge',
          },
          {
            title: 'Button',
            to: 'components-button',
          },
          {
            title: 'Chip',
            to: 'components-chip',
          },
          {
            title: 'Dialog',
            to: 'components-dialog',
          },

          {
            title: 'Expansion Panel',
            to: 'components-expansion-panel',
          },
          {
            title: 'List',
            to: 'components-list',
          },
          {
            title: 'Menu',
            to: 'components-menu',
          },
          {
            title: 'Pagination',
            to: 'components-pagination',
          },

          {
            title: 'Snackbar',
            to: 'components-snackbar',
          },
          {
            title: 'Stepper',
            to: 'components-stepper',
          },
          {
            title: 'Tabs',
            to: 'components-tabs',
          },
          {
            title: 'Timeline',
            to: 'components-timeline',
          },
          {
            title: 'Tooltip',
            to: 'components-tooltip',
          },
          {
            title: 'Treeview',
            to: 'components-treeview',
          },
        ],
      },
    ],
  },
]
