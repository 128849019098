// import appAndPages from './app-and-pages'
// import chartsAndMaps from './charts-and-maps'
// import formsAndTables from './forms-and-tables'
// import uiElements from './ui-elements'

import others from './Demo/others'
import admin from './admin'
import dashboard from './dashboard'
import medical from './medical'
import site from './site'
import trainingAndSupport from './training-and-support'
//import practitioner from './practitioner'



// Array of sections
//export default [...dashboard, ...medical, ...practitioner, ...trainingAndSupport, ...site, ...admin, ...others]
export default [...dashboard, ...medical, ...trainingAndSupport, ...site, ...admin, ...others]
