const pages = [
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/DEMO/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/DEMO/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/DEMO/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/DEMO/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/knowledge-base',
    name: 'page-knowledge-base',
    component: () => import('@/views/DEMO/pages/knowledge-base/KnowledgeBase.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/pages/knowledge-base/:category',
    name: 'page-knowledge-base-category',
    component: () => import('@/views/DEMO/pages/knowledge-base/KnowledgeBaseCategory.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'page-knowledge-base',
    },
  },
  {
    path: '/pages/knowledge-base/:category/:slug',
    name: 'page-knowledge-base-question',
    component: () => import('@/views/DEMO/pages/knowledge-base/KnowledgeBaseQuestion.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'page-knowledge-base',
      resource: 'Public',
    },
  },
  {
    path: '/pages/account-settings',
    name: 'page-account-settings',
    component: () => import('@/views/DEMO/pages/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pages/faq',
    name: 'page-faq',
    component: () => import('@/views/DEMO/pages/faq/Faq.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/pages/access-control',
    name: 'page-access-control',
    component: () => import('@/views/DEMO/pages/access-control/AccessControl.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },

  {
    path: '/pages/support',
    name: 'page-support',
    component: () => import('@/views/pages/SupportTicket.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
]

export default pages
