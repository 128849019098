import ApiConfig from './ApiConfig'
import {
  ICD10ProblemCodeClient_V1,
  PatientProblemClient_V1,
  ConditionVerificationClient_V1 }
  from './generated/api.generated.v1'

import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProblems() {
      const api = new ICD10ProblemCodeClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getProblemCodes()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postICD10Problems() {
      const api = new ICD10ProblemCodeClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postProblemCodes()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    fetchPatientProblems(context, patientId) {
      const api = new PatientProblemClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientProblems(patientId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientProblem(context, patientProblemDTO) {
      const api = new PatientProblemClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientProblem(patientProblemDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Verifications

    fetchConditionVerifications() {
      const api = new ConditionVerificationClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getConditionVerifications()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
