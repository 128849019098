import ApiConfig from './ApiConfig'
import { PatientClient_V1, PatientAlertClient_V1, PatientVitalsClient_V1 } from './generated/api.generated.v1'

import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Patient
    fetchPatients() {
      const api = new PatientClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatients()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPatient(context, patientId) {
      const api = new PatientClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatient(patientId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSitePatients() {
      const api = new PatientClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSitePatients()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSitePatientsByStatus(context, payload) {
      const api = new PatientClient_V1(new ApiConfig(), variables.INTERNAL_API)

      let status = true

      if (payload == 'Active') {
        status = true
      }
      if (payload == 'Inactive') {
        status = false
      }

      return new Promise((resolve, reject) => {
        api
          .getSitePatientsByStatus(status)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postSitePatient(context, patientDTO) {
      const api = new PatientClient_V1(new ApiConfig(), variables.INTERNAL_API)

      console.log('Patient Submit', patientDTO)

      return new Promise((resolve, reject) => {
        api
          .postSitePatient(patientDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletePatient(context, patientDTO) {
      const api = new PatientClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .deletePatient(patientDTO.id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Alerts
    fetchPatientAlerts(context, payload) {
      const api = new PatientAlertClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientAlerts(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientAlert(context, payload) {
      const api = new PatientAlertClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientAlert(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSiteAlertsByName(context, payload) {
      const api = new PatientAlertClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteAlertsByName(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Patient Vitals
    fetchLatestVitals(context, payload) {
      const api = new PatientVitalsClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getLatestVitals(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPatientVitals(context, payload) {
      const api = new PatientVitalsClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientVitals(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientVitals(context, payload) {
      const api = new PatientVitalsClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientVitals(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
