import { canNavigate } from '@/plugins/acl/routeProtection'
import { SimpleCrypto } from 'simple-crypto-js'
import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './Demo/apps'
import userInterface from './Demo/user-interface'
import admin from './admin'
import dashboard from './dashboard'
import medical from './medical'
import pages from './pages'
import site from './site'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      if (localStorage.getItem('encryptedRolePackage')) {
        const CryptoSecretKey = 'DetainEHRSecretKeyThatWillAllowUsToBeSuperSecure'
        const EncryptedData = localStorage.getItem('encryptedRolePackage')

        const SCrypt = new SimpleCrypto(CryptoSecretKey)
        const decryptedRolePackage = SCrypt.decrypt(EncryptedData)

        // const userData = localStorage.getItem('userData')

        // const userData = JSON.parse(decryptedUserData)
        const userRoles = decryptedRolePackage

        // console.log('Index routing', userRoles, userData)

        // const userRole = userData && userData.role ? userData.rolePackage : null

        if (userRoles !== null) return { name: 'dashboard-home' }

        if (userRoles === null || userRoles === undefined) return { name: 'page-access-control' }
      }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('@/views/user/QRCode.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/mfa-login',
    name: 'mfa-login',
    component: () => import('@/views/LoginMFA.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/confirm-email/:email/:token',
    name: 'auth-confirm-email',
    component: () => import('@/views/ConfirmEmail.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/forgot-password/:email/:token',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    props: {
      email: {
        type: String,
        required: true,
      },
      token: {
        type: String,
        required: false,
      },
    },
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: false,
    },
  },
  ...dashboard,
  ...userInterface,
  ...apps,
  ...admin,
  ...pages,
  ...medical,
  ...site,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  // const userData = localStorage.getItem('userData')

  // const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

  const isLoggedIn = localStorage.getItem('userAbility')

  // console.log('Index Is Logged In Before Each', isLoggedIn)
  // console.log(canNavigate(to))
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // console.log('not authorized ', to)

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // console.log('Authorized ', to)

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
