<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="time"
      :rules="[required]"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          :class="controlFieldClass"
          dense
          :prepend-icon="icons.mdiCalendar"
          hint="16:00"
          :readonly="!isEdit"
          v-bind="attrs"
          v-on="on"
          @change="update()"
        ></v-text-field>
      </template>
      <v-time-picker
        v-model="time"
        :readonly="!isEdit"
        color="primary"
        format="24hr"
        @change="update()"
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(time)"
        >
          OK
        </v-btn>
      </v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'StartTimeControl',
  extends: BaseControlSkeleton,
  data() {
    return {
      time: null,
      menu: false,
      isEdit: true,

      icons: { mdiCalendar },
    }
  },
  mounted() {
    this.$el.time = this.value
    console.log('Time', this.time, this.value)
    this.time = this.value != null ? this.value : this.time

    if (this.time != null) {
      this.isEdit = false
    } else {
      this.time = new Date().toTimeString().trim().split(' ')[0]
    }
    this.updateValue(this.time)
  },
  methods: {
    update() {
      console.log(this.time)
      this.updateValue(this.time)
    },
  },
}
</script>
