<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
    <v-overlay v-if="dialog">
      <v-dialog
        v-model="dialog"
        persistent
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            color="primary"
            class="mt-6"
            v-bind="attrs"
            v-on="on"
            @click="getOTP()"
          >
            Login
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Paused due to inactivity
          </v-card-title>

          <v-card-text>
            You have two choices
            <ol>
              <li><strong>Stay logged in:</strong> Check your email for a new passcode, you will not lose progress.</li>
              <li><strong>Sign out:</strong> </li>
            </ol>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-text-field
              v-model="otp"
              outlined
              label="Pass Code"
              placeholder="Enter OTP from your email"
            >
            </v-text-field>

            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="preferToLogout()"
            >
              Logout
            </v-btn>
            <v-btn
              color="primary"
              text
              :disabled="buttonIsDisabled"
              @click.once="agree = true"
              @click="submitForm()"
            >
              Stay logged in
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-overlay>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, getCurrentInstance } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import { initialAbility } from '@/plugins/acl/config'
import useAuthenticationService from '@/store/useAuthenticationService'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  data() {
    const CryptoSecretKey = 'DetainEHRSecretKeyThatWillAllowUsToBeSuperSecure'

    return {
      buttonIsDisabled: true,
      timer: null,
      dialog: false,
      agree: false,
      otp: '',
      CryptoSecretKey,
    }
  },
  watch: {
    otp(newValue, oldValue) {
      this.buttonIsDisabled = false
    },
  },
  beforeMount() {
    window.addEventListener('load', this.onLoad)
    window.addEventListener('beforeunload', this.onUnload)
  },
  beforeDestroy() {
    window.removeEventListener('load', this.onLoad)
    window.removeEventListener('beforeunload', this.onUnload)
    window.removeEventListener('mousemove', this.resetTimer)
    window.removeEventListener('keypress', this.resetTimer)
  },

  async beforeCreate() {
    if (localStorage.getItem('encryptedUserId')) {
      const authStore = useAuthenticationService()
      await authStore.initStore()
    }
  },
  mounted() {
    if (localStorage.getItem('encryptedUserId')) {
      const authStore = useAuthenticationService()

      this.$nextTick(() => {
        window.setInterval(async () => {
          await authStore.refreshAuth()
        }, 540000) // 9 minutes
      })

      this.setupEventListeners()
      this.resetTimer()
    }
  },
  methods: {
    setupEventListeners() {
      window.addEventListener('mousemove', this.resetTimer.bind(this))
      window.addEventListener('keypress', this.resetTimer.bind(this))
    },
    resetTimer() {
      clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        this.dialog = true

        const authStore = useAuthenticationService()
        await authStore.triggerInactivityOTP()
      }, 600000) // 10 minutes for prod
    },
    preferToLogout() {
      this.dialog = false
      this.otp = ''
      this.buttonIsDisabled = true

      localStorage.clear()
      this.$router.push('/login')
    },
    async submitForm() {
      if (this.agree) {
        const authStore = useAuthenticationService()
        await authStore.setUserToActive(this.otp)
        this.dialog = false
        this.otp = ''
        this.buttonIsDisabled = true

        if (!localStorage.getItem('encryptedUserId')) this.$router.push('/login')
      }
    },
    onLoad() {},
    onUnload() {
      // Reset ability
      this.vm.$ability.update(initialAbility)
    },
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const vm = getCurrentInstance().proxy

    const resolveLayoutVariant = computed(() => {
      // //console.log('Layout Variant ', route.value.meta.layout)
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      vm,
    }
  },
}
</script>
