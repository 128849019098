<template>
  <v-radio-group
    :id="control.uniqueId"
    v-model="providerType"
    row
    :name="control.name || control.uniqueId"
    :readonly="!isEdit"
  >
    <v-radio
      v-for="option, index in radioOptions"
      :key="index"

      :label="option"

      :value="option"
      @change="update(option)"
    >
    </v-radio>
  </v-radio-group>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'

export default {
  name: 'ProviderTypeControl',
  extends: BaseControlSkeleton,
  data() {
    return {
      providerType: null,
      radioOptions: ['Nurse', 'MD', 'NP/PA', 'Telemedicine'],
      isEdit: true,
    }
  },
  mounted() {
    this.$el.providerType = this.value
    this.providerType = this.value != null ? this.value : this.providerType
    if (this.providerType != null) {
      this.isEdit = false
    } else {
      this.providerType = 'Nurse'
    }
    this.updateValue(this.providerType)
  },

  methods: {
    update(option) {
      this.providerType = option
      this.updateValue(this.providerType)
    },
  },
}
</script>
