import mock from '@/@fake-db/mock'

/* eslint-disable global-require */
const data = [
  {
    id: 1,
    assignedto: 'Nurse Tasks',
    type: 'TB Test',
    title: 'TB Test to complete',
    description: 'TB Test started, to be read',
    created: '10/12/2022',
    duedate: '10/15/2022',
    link: '/apps/patient-chart',
    patientid: '178451',
    patientname: 'Homer Simpson',
    patientlocation: 'Block B B74',
    status: 'started',
  },
  {
    id: 5,
    assignedto: 'Nurse Tasks',
    type: 'TB Test',
    title: 'TB Test to complete',
    description: 'TB Test to start',
    created: '10/13/2022',
    duedate: '10/16/2022',
    link: '/apps/patient-chart',
    patientid: '15434861',
    patientname: 'Jenny Black',
    patientlocation: 'Block F A78',
    status: 'New',
  },
  {
    id: 2,
    assignedto: 'Nurse Tasks',
    type: 'Medical History',
    title: 'New MedHistory to complete for 456464321 - Sherry Smith',
    description: 'Medical History, Intake Review, ASQ form, and Medication Verification',
    created: '10/12/2022',
    duedate: '10/22/2022',
    link: '/apps/patient-chart',
    patientid: '456464321',
    patientname: 'Sherry Smith',
    patientlocation: 'Block F FL',
    status: 'New',
  },
  {
    id: 3,
    assignedto: 'Practitioner Tasks',
    type: 'Sick Call',
    title: 'Patient requested doctor visit due to back pain',
    description: 'Patient reporting pain shooting down his back when moving arm',
    created: '10/12/2022',
    duedate: '10/18/2022',
    link: '/apps/patient-chart',
    patientid: '884515',
    patientname: 'Jerry Jones',
    patientlocation: 'Block A1',
    status: 'New',
  },
  {
    id: 4,
    assignedto: 'QMHP Tasks',
    type: 'ASQ Form',
    title: 'New ASQ Form has been referred to you for review',
    description: 'Please review ASAP!',
    created: '10/10/2022',
    duedate: '10/15/2022',
    link: '/apps/patient-chart',
    patientid: '874215232',
    patientname: 'Jelly Bean',
    patientlocation: 'Block B JB',
    status: 'complete',
  },
]

// ------------------------------------------------
// GET: Return Tasks
// ------------------------------------------------

mock.onGet('/apps/tasklist/tasks').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', status = '', options = {} } = config.params

  // eslint-disable-next-line object-curly-newline
  const { sortBy = '', sortDesc = '', page, itemsPerPage } = options

  /* eslint-enable */

  const queryLowered = q.toLowerCase()

  let filteredData = data.filter(
    task =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (task.title.toLowerCase().includes(queryLowered) || task.patientname.toLowerCase().includes(queryLowered)) &&
      task.complete === (status || task.complete),
  )
  /* eslint-enable  */

  // sorting
  if (sortBy.length === 1 && sortDesc.length === 1) {
    filteredData = filteredData.sort((a, b) => {
      let sortA = ''
      let sortB = ''
      if (sortBy[0] === 'id') {
        sortA = a[sortBy[0]].name
        sortB = b[sortBy[0]].name
      } else {
        sortA = a[sortBy[0]]
        sortB = b[sortBy[0]]
      }

      if (sortDesc[0]) {
        if (sortA < sortB) return 1
        if (sortA > sortB) return -1

        return 0
      }

      if (sortA < sortB) return -1
      if (sortA > sortB) return 1

      return 0
    })
  }

  return [
    200,
    {
      filteredData:
        itemsPerPage !== -1 ? filteredData.slice((page - 1) * itemsPerPage, page * itemsPerPage) : filteredData,
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single task
// ------------------------------------------------
mock.onGet(/\/apps\/tasklist\/tasks\/\d+/).reply(config => {
  // Get event id from URL
  let tasklistId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  tasklistId = Number(tasklistId)

  const tasklistIndex = data.findIndex(e => e.id === tasklistId)
  const tasklist = data[tasklistIndex]
  const responseData = {
    tasklist,
  }

  if (tasklist) return [200, responseData]

  return [404]
})

// ------------------------------------------------
// GET: Add Task
// ------------------------------------------------
mock.onPost('/apps/tasklist/tasks').reply(config => {
  // Get event from post data
  const { task } = JSON.parse(config.data)

  // Assign Status
  task.status = 'active'

  const { length } = data.tasksList
  let lastIndex = 0
  if (length) {
    lastIndex = data.taskslist[length - 1].id
  }
  task.id = lastIndex + 1

  data.tasksList.push(task)

  return [201, { task }]
})
