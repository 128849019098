<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
    <v-radio-group
      v-model="selfHarm"
      row
      :readonly="!isEdit"
    >
      <v-radio
        v-for="option, index in radioOptions"
        :key="index"

        :label="option"

        :value="option"
        @change="update(option)"
      >
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'

export default {
  name: 'SelfHarmControl',
  extends: BaseControlSkeleton,
  data() {
    return {
      selfHarm: null,
      radioOptions: ['No', 'Yes'],
      isEdit: true,
    }
  },
  mounted() {
    this.$el.selfHarm = this.value
    this.selfHarm = this.value != null ? this.value : this.selfHarm
    if (this.selfHarm != null) {
      this.isEdit = false
    } else {
      this.selfHarm = 'No'
    }
    this.updateValue(this.selfHarm)
  },

  methods: {
    update(option) {
      this.selfHarm = option
      this.updateValue(this.selfHarm)
    },
  },
}
</script>
