const site = [
  //
  //* ——— Activity Log ——————————————————
  //

  {
    path: '/site/interface-logs',
    name: 'if-logs',
    component: () => import('@/views/activitylogs/ViewiInterfaceLogs.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },
  {
    path: '/site/iam-logs',
    name: 'iam-logs',
    component: () => import('@/views/activitylogs/ViewIAM.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },
  {
    path: '/site/activity-logs',
    name: 'activity-logs',
    component: () => import('@/views/activitylogs/ViewAll.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },

  //
  //* ——— User List——————————————————
  //

  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/register-user',
    name: 'register-user',
    component: () => import('@/views/user/RegisterUser.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/site/user-list',
    name: 'site-user-list',
    component: () => import('@/views/user/list/UserList.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },

  //
  //* ——— Medication List ——————————————————
  //

  {
    path: '/site/med-list',
    name: 'site-med-list',
    component: () => import('@/views/medication/MedicationList.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },

  {
    path: '/site/med-time-list',
    name: 'site-med-time-list',
    component: () => import('@/views/medication/MedTimeList.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },

  //
  //* ——— Error Mark List——————————————————
  //

  {
    path: '/site/error-mark-list',
    name: 'error-mark-list',
    component: () => import('@/views/error/ErrorMarkList.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },

  //
  //* ——— Symptom List ——————————————————
  //

  {
    path: '/site/symptom-list',
    name: 'admin-symptom-list',
    component: () => import('@/views/symptom/SymptomList.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },

  //
  //* ——— Site Setting List——————————————————
  //

  {
    path: '/site/setting',
    name: 'site-setting-list',
    component: () => import('@/views/site/setting/SiteSettingList.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },

  //
  //* ——— Form List ——————————————————
  //

  {
    path: '/site/form-list',
    name: 'site-form-list',
    component: () => import('@/views/form/FormList.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },
  {
    path: '/apps/form-add',
    name: 'apps-form-add',
    component: () => import('@/apps/form/FormAddEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },
  {
    path: '/apps/form-render',
    name: 'apps-form-render',
    component: () => import('@/apps/form/FormRender.vue'),
    meta: {
      layout: 'content',
      resource: 'Site',
    },
  },
]

export default site
