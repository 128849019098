import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useTaskList() {
  const taskListTable = ref([])
  const reviewListTable = ref([])
  const taskTypeList = ref([])
  const taskData = ref({})
  const siteTaskData = ref({})
  const pdfList = ref([])

  const taskListColumns = [
    { text: 'PATIENT', value: 'patient.displayName' },
    { text: 'PATIENT ID', value: 'patient.jacketId' },
    { text: 'BOOKING ID', value: 'patient.bookingId' },
    { text: 'TASK', value: 'taskName' },
    { text: 'DESCRIPTION', value: 'taskDescription' },
    { text: 'START DATE', value: 'startDate', width: 150 },
    { text: 'DUE DATE', value: 'dueDate', width: 150 },
    { text: 'STATUS', value: 'status' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const reviewListColumns = [
    { text: 'PATIENT', value: 'patient.displayName' },
    { text: 'PATIENT ID', value: 'patient.jacketId' },
    { text: 'BOOKING ID', value: 'patient.bookingId' },
    { text: 'TASK', value: 'display' },
    { text: 'RECORDED DATE', value: 'recordedDate' },
    { text: 'RECORDED BY', value: 'recordedBy' },
    { text: 'STATUS', value: 'signStatus' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const documentReviewListColumns = [
    { text: 'TITLE', value: 'docTitle' },
    { text: 'RECORDED DATE', value: 'recordedDate' },
    { text: 'RECORDED BY', value: 'recordedBy' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const currentType = ref('Medical')
  const currentReviewType = ref('Form')
  const statusFilter = ref(null)
  const callResponse = ref(null)
  const totalTaskListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['dueDate'],
    sortDesc: [false],
    groupBy: ['patient.jacketId'],
    groupDesc: [true],
    itemsPerPage: 15,
    multiSort: true,
  })
  const siteTaskTotalLocal = ref([])
  const selectedRows = ref([])
  const selectedTask = ref([])
  const taskStatus = ref(['New', 'Active', 'Complete', 'Waiting', 'Late'])
  const reviewType = ref(['Form', 'Document', 'Order', 'Behavioral Health'])

  const displayDate = date => new Date(date).toISOString().split('T')[0]
  const postDate = date => new Date(date)

  // Site Tasks
  // fetch data
  const fetchSiteTasks = async () => {
    await store
      .dispatch('app-task/fetchSiteTasks')
      .then(response => {
        taskListTable.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error fetchSiteTasks due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedRows.value = []
    fetchSiteTasks()
  })

  const fetchSiteTasksByType = async () => {
    await store
      .dispatch('app-task/fetchSiteTasksByType', currentType.value)
      .then(response => {
        taskListTable.value = response

        taskListTable.value.forEach(task => {
          task.startDate = task.startDate === null ? null : displayDate(task.startDate)
          task.dueDate = task.dueDate === null ? null : displayDate(task.dueDate)
        })

        loading.value = false
      })
      .catch(error => {
        alert(`error fetchSiteTasksByType due to ${error}`)
      })
  }

  watch([currentType], () => {
    loading.value = true
    selectedRows.value = []
    fetchSiteTasksByType()
  })

  // Patient Task
  const postPatientTask = async patientTaskDTO => {
    patientTaskDTO.startDate = patientTaskDTO.startDate === null ? null : postDate(patientTaskDTO.startDate)
    patientTaskDTO.dueDate = patientTaskDTO.dueDate === null ? null : postDate(patientTaskDTO.dueDate)

    await store
      .dispatch('app-task/postPatientTask', patientTaskDTO)
      .then(response => {
        callResponse.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error postPatientTask due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedRows.value = []
    postPatientTask()
  })

  // Patient Review

  const fetchPatientReviewsByType = async () => {
    await store
      .dispatch('app-task/fetchSiteReviewsByType', currentReviewType.value)
      .then(response => {
        reviewListTable.value = response

        reviewListTable.value.forEach(task => {
          task.recordedDate = task.recordedDate === null ? null : displayDate(task.recordedDate)
          task.reviewedDate = task.reviewedDate === null ? null : displayDate(task.reviewedDate)
        })

        loading.value = false
      })
      .catch(error => {
        alert(`error fetchPatientReviewsByType due to ${error}`)
      })
  }

  watch([currentReviewType], () => {
    loading.value = true
    selectedRows.value = []
    fetchPatientReviewsByType()
  })

  const postPatientReview = async patientReviewDTO => {
    patientReviewDTO.recordedDate =
      patientReviewDTO.recordedDate === null ? null : postDate(patientReviewDTO.recordedDate)
    patientReviewDTO.reviewedDate =
      patientReviewDTO.reviewedDate === null ? null : postDate(patientReviewDTO.reviewedDate)

    await store
      .dispatch('app-task/postPatientReview', patientReviewDTO)
      .then(response => {
        callResponse.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error postPatientReview due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedRows.value = []
    postPatientReview()
  })

  // fetch data
  const fetchTaskTypes = async () => {
    await store
      .dispatch('app-task/fetchTaskTypes')
      .then(response => {
        taskTypeList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error fetchTaskTypes due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    fetchTaskTypes()
  })

  // fetch pdfs
  const fetchPDFs = async () => {
    loading.value = true
    await store
      .dispatch('app-task/fetchPDFs')
      .then(response => {
        pdfList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error FetchPDFs due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    fetchPDFs()
  })

  const postPDF = async pdfReviewDTO => {
    await store
      .dispatch('app-task/postPDF', pdfReviewDTO)
      .then(response => {
        console.log('PDF', response)
        loading.value = false
      })
      .catch(error => {
        alert(`error PostPDF due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    postPDF()
  })

  const deletePDF = async itemId => {
    console.log('Delete PDF', itemId)
    await store
      .dispatch('app-task/deletePDF', itemId)
      .then(response => {
        console.log('PDF Removed', response)

        loading.value = false
      })
      .catch(error => {
        alert(`error deletePDF due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    deletePDF()
  })


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    taskListTable,
    taskListColumns,
    searchQuery,
    statusFilter,
    totalTaskListTable,
    loading,
    options,
    siteTaskTotalLocal,
    selectedRows,
    siteTaskData,
    selectedTask,
    taskData,
    callResponse,
    currentType,
    currentReviewType,

    fetchSiteTasks,
    fetchSiteTasksByType,

    fetchTaskTypes,
    taskTypeList,

    postPatientTask,
    taskStatus,

    postPatientReview,
    fetchPatientReviewsByType,
    reviewListTable,
    reviewType,
    reviewListColumns,

    documentReviewListColumns,
    fetchPDFs,
    pdfList,
    postPDF,
    deletePDF,

  }
}
