<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          :class="controlFieldClass"
          dense
          :prepend-icon="icons.mdiCalendar"
          :readonly="!isEdit"
          v-bind="attrs"
          v-on="on"
          @change="update()"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :readonly="!isEdit"
        no-title
        scrollable
        @change="update()"
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(date)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'StartedControl',
  extends: BaseControlSkeleton,
  data() {
    return {
      date: null,
      menu: false,
      isEdit: true,

      icons: { mdiCalendar },
    }
  },
  mounted() {
    this.$el.date = this.value
    this.date = this.value != null ? this.value : this.date
    if (this.date != null) {
      this.isEdit = false
    } else {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
    }
    this.updateValue(this.date)
  },
  methods: {
    update() {
      console.log(this.date)
      this.updateValue(this.date)
    },
  },
}
</script>
