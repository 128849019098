import { mdiHelpCircleOutline, mdiHelpRhombusOutline, mdiLifebuoy } from '@mdi/js'

export default [
  {
    subheader: 'TRAINING & SUPPORT',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Knowledge Base',
    icon: mdiLifebuoy,
    to: 'page-knowledge-base',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'FAQs',
    icon: mdiHelpCircleOutline,
    to: 'page-faq',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Support Request',
    icon: mdiHelpRhombusOutline,
    to: 'page-support',
    resource: 'Public',
    action: 'read',
  },
]
