<template>
  <div
    :id="control.uniqueId"
    :name="control.name || control.uniqueId"
  >
    <signature-pad
      :signature="signature"
      @sign="updateSign"
    >
    </signature-pad>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'
import SignaturePad from '@/apps/sign/SignaturePad.vue'

export default {
  name: 'SignatureControl',
  components: { SignaturePad },
  extends: BaseControlSkeleton,
  data() {
    return {
      signature: null,
    }
  },
  created() {
    this.signature = this.value != null ? this.value : null

    console.log('Mounted Form Sign', this.signature)

    this.updateSign(this.signature)
  },
  methods: {
    updateSign(sign) {
      this.signature = sign
      console.log('Sign Update')
      this.updateValue(this.signature)
    },
  },
}
</script>
