import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.control.uniqueId,"name":_vm.control.name || _vm.control.uniqueId}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.time,"rules":[_vm.required],"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({class:_vm.controlFieldClass,attrs:{"dense":"","prepend-icon":_vm.icons.mdiCalendar,"hint":"16:00","readonly":!_vm.isEdit},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VTimePicker,{attrs:{"readonly":!_vm.isEdit,"color":"primary","format":"24hr"},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.time)}}},[_vm._v(" OK ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }