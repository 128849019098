const dashboard = [
  {
    path: '/dashboards/home',
    name: 'dashboard-home',
    component: () => import('@/views/dashboards/home/Home.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/dashboards/statistics',
    name: 'dashboard-statistics',
    component: () => import('@/views/dashboards/statistics/Statistics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboards/crm',
    name: 'dashboard-crm',
    component: () => import('@/views/DEMO/dashboards/crm/Crm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboards/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/DEMO/dashboards/analytics/Analytics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboards/eCommerce',
    name: 'dashboard-eCommerce',
    component: () => import('@/views/DEMO/dashboards/ecommerce/Ecommerce.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default dashboard
