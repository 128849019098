import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'

export default function usePatientList() {
  const sitePatientList = ref([])
  const siteAlerts = ref([])
  const patientVitalsList = ref([])
  const patientVitals = ref([])
  const patientAlerts = ref([])
  const sitePatient = ref(null)
  const patientList = ref([])
  const patientData = ref({})
  const errorMessage = ref()

  const patientFields = [
    { text: 'JACKET ID', value: 'jacketId' },
    { text: 'BOOKING ID', value: 'bookingId' },
    { text: 'FIRST NAME', value: 'firstName' },
    { text: 'LAST NAME', value: 'lastName' },
    { text: 'BIRTH DATE', value: 'birthDate' },
    { text: 'BOOKING DATE', value: 'bookingDate' },
    { text: 'RELEASE DATE', value: 'releaseDate' },
    { text: 'STATUS', value: 'isActive' },
    { text: 'ACTIONS', value: 'actions' },
  ]

  const patientAlertColumns = [
    { text: 'ALERT', value: 'alert.alertName' },
    { text: 'EXPECTED RESOLUTION', value: 'expectedResolutionDate' },
    { text: 'COMMENTS', value: 'comment' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const patientVitalsColumns = [
    { text: 'TEMPERATURE', value: 'temperature' },
    { text: 'RESPIRATIONS', value: 'respirations' },
    { text: 'O2%', value: 'o2SaturationPercent' },
    { text: 'SYSTOLIC', value: 'systolic' },
    { text: 'DIASTOLIC', value: 'diastolic' },
    { text: 'PULSE', value: 'pulse' },
    { text: 'WEIGHT', value: 'weight' },
    { text: 'HEIGHT', value: 'height' },
    { text: 'PRE-PF', value: 'prePeakFlow' },
    { text: 'POST-PF', value: 'postPeakFlow' },
    { text: 'BLOOD SUGAR', value: 'bloodSugar' },
    { text: 'BLOODSUGAR COMMENTS', value: 'bloodSugarComments' },
    { text: 'DATE', value: 'modifiedDate', width: 175 },
    { text: 'USER', value: 'modifiedBy' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref(null)
  const sortBy = ref('jacketId')
  const sortDesc = ref(false)
  const statusFilter = ref(true)
  const genderFilter = ref(0)
  const ageFilter = ref(2)
  const totalPatientListTable = ref(0)
  const loading = ref(true)
  const patientTotalLocal = ref([])
  const selectedItem = ref()
  const options = ref({
    itemsPerPage: 5,
  })

  const calculateAge = dob => {
    const currentDate = new Date()
    const difference = currentDate - dob
    const age = Math.floor(difference / 31557600000)

    return age
  }

  const formatCase = string => string.toLowerCase()

  //const displayDate = date => new Date(date).toISOString().split('T')[0]

  const formatDate = date => {
    let dates = moment(String(date)).format('YYYY-MM-DD')

    if (dates === 'Invalid date') {
      dates = null
    }

    return dates
  }

  const postDate = date => new Date(date)

 // Fetches Patients by status if Both not selected - otherwise gets all patients and filters them
 const fetchPatients = () => {
  loading.value = true
  let status = 'Active'

  if (statusFilter.value === 0) {
    status = 'Active'
  }
  if (statusFilter.value === 1) {
    status = 'Inactive'
  }
  if (statusFilter.value === 2) {
    status = null
  }

  const apiString = status == null ? 'app-patient/fetchSitePatients' : 'app-patient/fetchSitePatientsByStatus'
  store
    .dispatch(apiString, status)
    .then(response => {
      sitePatientList.value = response

      let age = null
      let gender = null

      if (ageFilter.value === 0) {
        age = 'Adult'
      }
      if (ageFilter.value === 1) {
        age = 'Juvenile'
      }
      if (ageFilter.value === 2) {
        age = null
      }

      if (genderFilter.value === 0) {
        gender = null
      }
      if (genderFilter.value === 1) {
        gender = 'Male'
      }
      if (genderFilter.value === 2) {
        gender = 'Female'
      }

      if (ageFilter !== 0) {
        sitePatientList.value = sitePatientList.value.filter(patient => {
          const juvieAlert = patient.patientAlerts.filter(alert => alert.alert.alertName === 'Juvenile')[0]

          if (age === 'Adult' && juvieAlert != null) {
            return patient.Id === juvieAlert.patientId
          }
          if (age === 'Juvenile' && juvieAlert == null) {
            const pt = patient.Id

            return pt
          }

          return patient
        })
      }

      sitePatientList.value = sitePatientList.value.filter(patient => {
        if (gender != null) {
          return patient.gender.display === gender
        }

        return patient
      })

      sitePatientList.value = sitePatientList.value.sort((p1, p2) => {
        if (sortDesc.value === false) {
          if (p1[sortBy.value] < p2[sortBy.value]) {
            return -1
          }
          if (p1[sortBy.value] > p2[sortBy.value]) {
            return 1
          }
        } else {
          if (p1[sortBy.value] > p2[sortBy.value]) {
            return -1
          }
          if (p1[sortBy.value] < p2[sortBy.value]) {
            return 1
          }
        }

        return 0
      })

      if (searchQuery.value != null && searchQuery.value !== ' ') {
        sitePatientList.value = sitePatientList.value.filter(patient => {
          const search = formatCase(searchQuery.value)

          return (
            formatCase(patient.jacketId).includes(search) ||
            formatCase(patient.bookingId).includes(search) ||
            formatCase(patient.displayName).includes(search)
          )
        })
      }

      sitePatientList.value.forEach(patient => {
        patient.birthDate = patient.birthDate === null ? null : formatDate(patient.birthDate)
        patient.bookingDate = patient.bookingDate === null ? null : formatDate(patient.bookingDate)
        patient.releaseDate = patient.releaseDate === null ? null : formatDate(patient.releaseDate)
      })

      // remove loading state
      loading.value = false
    })
    .catch(error => {
      console.log(error)
    })
}

watch([statusFilter, genderFilter, ageFilter, sortBy, sortDesc], () => {
  fetchPatients()
})

  // fetch data
  const fetchAllPatients = () => {
    store
      .dispatch('app-patient/fetchSitePatients')
      .then(response => {
        sitePatientList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    fetchAllPatients()
  })

  // fetch data
  const fetchPatient = async patientId => {
    await store
      .dispatch('app-patient/fetchPatient', patientId)
      .then(response => {
        sitePatient.value = response

        sitePatient.value.age = sitePatient.value.birthDate === null ? null : calculateAge(sitePatient.value.birthDate)
        sitePatient.value.birthDate =
          sitePatient.value.birthDate === null ? null : formatDate(sitePatient.value.birthDate)
        sitePatient.value.bookingDate =
          sitePatient.value.bookingDate === null ? null : formatDate(sitePatient.value.bookingDate)
        sitePatient.value.releaseDate =
          sitePatient.value.releaseDate === null ? null : formatDate(sitePatient.value.releaseDate)

        loading.value = false
      })
      .catch(error => {
        loading.value = false
        alert(`error fetchPatient due to ${error}`)
      })
  }

  watch([], () => {
    fetchPatient()
  })

  // post data
  const postSitePatient = patientDTO => {
    patientDTO.birthDate = patientDTO.birthDate === null ? null : postDate(patientDTO.birthDate)
    patientDTO.bookingDate = patientDTO.bookingDate === null ? null : postDate(patientDTO.bookingDate)
    patientDTO.releaseDate = patientDTO.releaseDate === null ? null : postDate(patientDTO.releaseDate)
    loading.value = true
    store
      .dispatch('app-patient/postSitePatient', patientDTO)
      .then(response => {
        if (response.errors !== undefined && response.errors !== null) {
          errorMessage.value = response.errors
        }

        loading.value = false
      })
      .catch(error => {
        loading.value = false
        alert(`error postSitePatient due to ${error}`)
      })
  }

  watch([], () => {
    postSitePatient(patientData)
  })

  // Patient Alert
  const fetchPatientAlerts = patientId => {
    store
      .dispatch('app-patient/fetchPatientAlerts', patientId)
      .then(response => {
        patientAlerts.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  watch([], () => {
    fetchPatientAlerts()
  })

  const postPatientAlert = patientAlertDTO => {
    patientAlertDTO.startDate = patientAlertDTO.startDate === null ? new Date() : postDate(patientAlertDTO.startDate)
    patientAlertDTO.expectedResolutionDate =
      patientAlertDTO.expectedResolutionDate === null ? new Date() : postDate(patientAlertDTO.expectedResolutionDate)
    store
      .dispatch('app-patient/postPatientAlert', patientAlertDTO)
      .then(response => {
        console.log(response)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  watch([], () => {
    postPatientAlert()
  })

  // fetch site alerts
  const fetchSiteAlertsByName = alertName => {
    store
      .dispatch('app-patient/fetchSiteAlertsByName', alertName)
      .then(response => {
        siteAlerts.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  watch([], () => {
    fetchSiteAlertsByName()
  })

  // Patient Vitals
  const fetchLatestVitals = patientId => {
    store
      .dispatch('app-patient/fetchLatestVitals', patientId)
      .then(response => {
        response.bloodPressure =
          (response.systolic == null ? '' : response.systolic) +
          (response.systolic == null && response.diastolic == null ? '' : '/') +
          (response.diastolic == null ? '' : response.diastolic)

        response.modifiedDate = response.modifiedBy == null ? null : formatDate(response.modifiedDate)

        patientVitals.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  watch([], () => {
    fetchLatestVitals()
  })

  const fetchPatientVitals = patientId => {
    store
      .dispatch('app-patient/fetchPatientVitals', patientId)
      .then(response => {
        patientVitalsList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  watch([], () => {
    fetchPatientVitals()
  })

  const postPatientVitals = patientVitalDTO => {
    store
      .dispatch('app-patient/postPatientVital', patientVitalDTO)
      .then(response => {
        console.log(response)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  watch([], () => {
    postPatientVitals()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const formatLocation = siteLocation => {
    if (siteLocation[0] !== null && siteLocation[0] !== undefined) {
      return `${siteLocation[0].pod.display},
              ${siteLocation[0].cell.display}`
    }

    return 'Not Housed'
  }

  return {
    sitePatientList,
    sitePatient,
    patientFields,
    searchQuery,
    statusFilter,
    genderFilter,
    ageFilter,
    totalPatientListTable,
    loading,
    options,
    patientTotalLocal,
    selectedItem,
    patientList,
    sortBy,
    sortDesc,
    errorMessage,
    patientAlertColumns,

    patientData,
    fetchPatients,
    fetchPatient,
    fetchAllPatients,
    postSitePatient,
    calculateAge,
    formatCase,
    formatLocation,

    fetchSiteAlertsByName,
    siteAlerts,

    postPatientAlert,
    fetchPatientAlerts,
    patientAlerts,

    postPatientVitals,
    patientVitalsList,
    patientVitalsColumns,
    fetchPatientVitals,

    patientVitals,
    fetchLatestVitals,
  }
}
