<template
  id="taskType-select"
>
  <v-combobox
    key="id"
    v-model="selectedTaskType"
    :items="taskTypeList"
    :multiple="multiple"
    item-text="display"
    :readonly="readonly"
    outlined
    label="Select Type"
    dense
    @change="selectTaskType(selectedTaskType)"
  >
  </v-combobox>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import taskService from '@/services/TaskService'
import useTaskService from '@/store/useTaskService'

export default {
  props: {
    taskType: {
      type: String,
      default: null,
    },
    typeIds:{
      type: Array,
      default: () => (null),
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    //console.log('Task Type', props.taskType)
    const TASK_APP = 'app-task'

    // Register module
    if (!store.hasModule(TASK_APP)) {
      store.registerModule(TASK_APP, taskService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP)) {
        store.unregisterModule(TASK_APP)
      }
    })

    const { taskTypeList, fetchTaskTypes } = useTaskService()

    //const selectedTaskType = (props.multiple) || (props.taskType.formGroup != null && props.taskType.formGroup !== undefined) ? ref(props.taskType.formGroup) : ref(props.taskType)

    const selectedTaskType = ref([])

    const selectTaskType = () => {
      emit('task-type-select', selectedTaskType.value)
    }

    return {
      taskTypeList,
      selectedTaskType,
      fetchTaskTypes,

      required,

      selectTaskType,
    }
  },
  mounted() {
    this.fetchTaskTypes()
    this.$watch('taskTypeList', () => {
      //this.typeIds = this.taskType != null ? [this.taskType.id] : this.typeIds

      if (this.typeIds != null) {

        this.taskTypeList.forEach(type => {
          if ((this.typeIds.includes(type.id))) {
            if (this.multiple){
              this.selectedTaskType.push(type)
            }
            else{
              this.selectedTaskType = type
            }
          }
          this.selectTaskType()
        })
      }
      else if (this.taskType != null) {
        this.taskTypeList.forEach(type => {
          if (type.display == this.taskType){
            this.selectedTaskType = type
          }
        })
        this.selectTaskType()
      }


    })
  },
}
</script>
