import { mdiAccountOutline, mdiFileClockOutline, mdiFileDocumentOutline, mdiFormSelect, mdiPill } from '@mdi/js'

export default [
  {
    subheader: 'SITE ADMINISTRATORS',
    resource: 'Site',
  },
  {
    icon: mdiAccountOutline,
    title: 'User List',
    to: 'site-user-list',
    resource: 'Site',
    action: 'manage',
  },
  {
    title: 'Forms',
    icon: mdiFormSelect,
    resource: 'Site',
    to: 'site-form-list',
    action: 'manage',
  },
  {
    title: 'Medications',
    icon: mdiPill,
    to: 'site-med-list',
    resource: 'Site',
    action: 'manage',
  },
  {
    title: 'Audit Forms',
    icon: mdiFileDocumentOutline,
    to: 'wip',
    resource: 'Site',
    action: 'manage',
  },
  {
    title: 'Med Time List',
    to: 'site-med-time-list',
    icon: mdiFileClockOutline,
    resource: 'Site',
    action: 'manage',
  },
  {
    title: 'Error Mark List',
    icon: mdiFileDocumentOutline,
    to: 'error-mark-list',
    resource: 'Site',
    action: 'manage',
  },

  {
    subheader: 'SITE LOGS',
    resource: 'Site',
  },

  {
    title: 'Activity Logs',
    icon: mdiFileDocumentOutline,
    to: 'activity-logs',
    resource: 'Site',
    action: 'manage',
  },
  {
    title: 'IAM Logs',
    icon: mdiFileDocumentOutline,
    to: 'iam-logs',
    resource: 'Site',
    action: 'manage',
  },
  {
    title: 'Interface Logs',
    icon: mdiFileDocumentOutline,
    to: 'if-logs',
    resource: 'Site',
    action: 'manage',
  },
]
