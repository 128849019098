import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.resolveLayoutVariant,{tag:"component",class:("skin-variant--" + _vm.appSkinVariant)},[_c('transition',{attrs:{"name":_vm.appRouteTransition,"mode":"out-in","appear":""}},[_c('router-view')],1),(_vm.dialog)?_c(VOverlay,[_c(VDialog,{attrs:{"persistent":"","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mt-6",attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.getOTP()}}},'v-btn',attrs,false),on),[_vm._v(" Login ")])]}}],null,false,478347176),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Paused due to inactivity ")]),_c(VCardText,[_vm._v(" You have two choices "),_c('ol',[_c('li',[_c('strong',[_vm._v("Stay logged in:")]),_vm._v(" Check your email for a new passcode, you will not lose progress.")]),_c('li',[_c('strong',[_vm._v("Sign out:")])])])]),_c(VDivider),_c(VCardActions,[_c(VTextField,{attrs:{"outlined":"","label":"Pass Code","placeholder":"Enter OTP from your email"},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.preferToLogout()}}},[_vm._v(" Logout ")]),_c(VBtn,{attrs:{"color":"primary","text":"","disabled":_vm.buttonIsDisabled},on:{"~click":function($event){_vm.agree = true},"click":function($event){return _vm.submitForm()}}},[_vm._v(" Stay logged in ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }