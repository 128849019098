<template>
  <v-combobox
    v-if="sitePatientList.length != 0"
    id="patientSelect"
    v-model="selectedItem"
    :items="sitePatientList"
    :rules="requiredRules"
    :prepend-inner-icon="icons.mdiAccountOutline"
    :readonly="readonly"
    item-text="jacketId"
    validate-on-blur
    small-chips
    cache-items
    hide-details
    outlined
    dense
    item-value="id"
    label="Patient ID"
    @change="selectPatient()"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Search for the Patient ID or type in the
          <strong>Patient ID</strong>
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attrs, on, item, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        v-on="on"
      >
        <v-avatar left>
          <v-img src="@/assets/images/avatars/1.png"></v-img>
        </v-avatar>
        <strong>{{ item.jacketId }} | {{ item.displayName }}</strong>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="indigo"
        height="40px"
      >
        <v-img src="@/assets/images/avatars/1.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> <strong>{{ item.jacketId }}</strong> ({{ item.displayName }})</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>

<script>
import { mdiAccountOutline } from '@mdi/js'
import patientService from '@/services/PatientService'
import usePatientService from '@/store/usePatientService'

import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

export default {
  props: {
    patient: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      patientId: this.patient.id !== undefined ? this.patient.id : this.$route.params.patientId,
    }
  },
  setup(props, { emit }) {
    // console.log('Patient Select Prop', props.patient)
    const PATIENT_APP = 'app-patient'

    // Register module
    if (!store.hasModule(PATIENT_APP)) {
      store.registerModule(PATIENT_APP, patientService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_APP)) store.unregisterModule(PATIENT_APP)
    })
    const { sitePatientList, fetchPatients, fetchAllPatients } = usePatientService()
    const selectedItem = ref(props.patient.id)
    const patients = ref([])
    const requiredRules = [v => v !== null || 'Select one patient.']

    const selectPatient = () => {
      emit('patient-select', selectedItem.value)
    }

    return {
      patients,
      requiredRules,

      fetchPatients,
      fetchAllPatients,
      sitePatientList,
      selectedItem,

      selectPatient,

      icons: {
        mdiAccountOutline,
      },
    }
  },
  created() {
    if(this.readonly){
      this.fetchAllPatients()
    }
    else{
      this.fetchPatients()
    }

    this.$watch('sitePatientList', () => {
      console.log('sitePatientList', this.patientId, this.readonly)
      if (this.patientId != null && this.patientId !== undefined) {
        this.sitePatientList.forEach(patient => {
          if (patient.id === this.patientId) {
            this.selectedItem = patient
          }
        })

        this.selectPatient()
      }
    })

  },
}
</script>
