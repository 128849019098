import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.control.uniqueId,"name":_vm.control.name || _vm.control.uniqueId}},[_c(VRow,[(_vm.isEdit)?_c(VCol,{attrs:{"cols":"12"}},[_c(VFileInput,{attrs:{"outlined":"","dense":"","counter":"","multiple":"","placeholder":"Browse for file(s)","label":"Upload File(s)","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c(VChip,{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}],null,false,522292842),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1):_c(VCol,{attrs:{"cols":"12"}},_vm._l((_vm.base64),function(image,index){return _c('div',{key:index},[(image !== '' && image !== null)?_c('div',[_vm._v(" Attachment "+_vm._s(index + 1)+" "),_c(VChip,{on:{"click":function($event){return _vm.exportForm(index + 1, image)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.mdiFileDocumentOutline)+" ")])],1)],1):_vm._e()])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }