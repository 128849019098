import ApiConfig from './ApiConfig'
import { PatientTaskClient_V1, PatientReviewClient_V1 } from './generated/api.generated.v1'
import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Task Type
    fetchTaskTypes() {
      const api = new PatientTaskClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getTaskTypes()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Site Tasks
    fetchSiteTasks() {
      const api = new PatientTaskClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteTasks()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSiteTasksByType(context, taskType) {
      const api = new PatientTaskClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteTasksByType(taskType)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientTask(context, patientTaskDTO) {
      const api = new PatientTaskClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientTask(patientTaskDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Site Reviews
    fetchSiteReviews() {
      const api = new PatientReviewClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteReviews()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSiteReviewsByType(context, reviewType) {
      const api = new PatientReviewClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteReviewsByType(reviewType)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientReview(context, patientReviewDTO) {
      const api = new PatientReviewClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientReview(patientReviewDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // document Review
    fetchPDFs() {
      const api = new PatientReviewClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPDFs()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPDF(context, payload) {
      const api = new PatientReviewClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPDF(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletePDF(context, payload) {
      const api = new PatientReviewClient_V1(new ApiConfig(), variables.INTERNAL_API)
      console.log('service', payload)

      return new Promise((resolve, reject) => {
        api
          .deletePDF(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
