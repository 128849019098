import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance, AxiosError, CancelToken } from 'axios'
import AuthService from './AuthService'
import { Config } from './generated/api.generated.v1'

let refreshingToken = false

export default class ApiConfig implements Config {
  private static unAuthorizedHandlers: Array<() => void>

  transformHeaders(options: AxiosRequestConfig): AxiosRequestConfig {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    options.headers = {
      ...options.headers,
      Authorization: this.getAuthorization(),
    }

    return options
  }

  async transformResult(
    url: string,
    response: AxiosResponse,
    processor: (response: AxiosResponse) => Promise<any>, // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) {
    const isFromLogin: boolean = (response.config.url?.toLowerCase()?.indexOf('login') ?? 0) > -1
    ;(response.config.url?.toLowerCase()?.indexOf('login') ?? 0) > -1
    if (response.status == 401 && !isFromLogin) {
      const isRefreshingToken = (response.config.url?.toLowerCase()?.indexOf('/identity/refreshtoken') ?? 0) > -1

      if (isRefreshingToken) {
        ApiConfig.FireUnauthorizedHandlers()
        return
      }
      const newResponse = await this._tryRefreshToken(response)
      if (newResponse == null) {
        return
      } else {
        response = newResponse
      }
    }

    return processor(response)
  }

  private static _authRetryCount = 0

  private async _tryRefreshToken(response: AxiosResponse) {
    if (!AuthService.GetToken()) {
      return
    }

    if (!refreshingToken) await this.refreshToken()

    while (refreshingToken) {
      await new Promise(r => setTimeout(r, 1000))
    }
    response = await this._retryRequest(response)

    return response
  }

  private async refreshToken(): Promise<boolean> {
    ApiConfig._authRetryCount++
    refreshingToken = true
    try {
      await new AuthService() //.RefreshToken();
    } catch (e) {
      ApiConfig.FireUnauthorizedHandlers()
      return false
    } finally {
      refreshingToken = false
    }
    return true
  }

  private async _retryRequest(response: AxiosResponse) {
    response.config = this.transformHeaders(response.config)

    response = await axios.request(response.config)

    return response
  }

  getAuthorization(): string {
    return `Bearer ${AuthService.GetToken()}`
  }

  getContentType(): string {
    return 'application/json'
  }

  static AddUnauthorizedHandler(handler: () => void) {
    if (ApiConfig.unAuthorizedHandlers == undefined) ApiConfig.unAuthorizedHandlers = new Array<() => void>()

    ApiConfig.unAuthorizedHandlers.push(handler)
  }

  static FireUnauthorizedHandlers() {
    ApiConfig.unAuthorizedHandlers.forEach(x => x())
    ApiConfig._authRetryCount = 0
  }
}
/* tslint:disable */
/* eslint-disable */
// eslint-disable
// ReSharper disable InconsistentNaming
